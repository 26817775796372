<template>
  <div class="wrap">
    <Navbar :title="$t('Setting.Set')"> </Navbar>
    <div class="setting">
      <div class="content">
        <div class="content-item" :class="{ state: NavIndex == index }" v-for="(item, index) in Label" :key="index"
          @click="MousedownEvent(index, item.url)">
          <div>
            <span class="item_text">{{ $t(item.text) }}</span>
          </div>
          <div>
            <span class="item_value">{{ $t(item.span) }}</span>
            <van-icon size="18" color="var(--lioght)" name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  ActionSheet,
  Button,
  Image,
  Toast,
} from "vant";
import Navbar from "@/components/JNav";
import { maskMiddle } from "@/utils/common"

export default {
  name: "PersonalCenter",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
    Navbar,
  },
  data() {
    return {
      userInfo: {},
      Label: [
        {
          id: "5",
          img: require("@/assets/icon/myInfo/pwd.png"),
          url: "Password",
          span: `Setting.Modify`,
          text: `My.PersonalInformation.Page.Password`,
        },
        {
          id: "6",
          img: require('@/assets/icon/myInfo/pwd.png'),
          url: "PayPassword",
          span: `Setting.Set`,
          text: `Setting.FundPassword`,
        },
        {
          id: "3",
          span: "Setting.Set",
          url: "Email",
          text: `newAdd.Email`,
        },
        {
          id: "4",
          img: require("@/assets/icon/myInfo/bank.png"),
          url: "BankCardAdd",
          span: `Setting.Check`,
          text: `Setting.ankCardManagement`,
        }
      ],
      NavIndex: -1, // 控制选中状态的
    };
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    // 标签鼠标按下
    MousedownEvent(num, url) {
      this.$router.push({ name: url });
    },
    // 获取用户信息
    getUserInfo() {
      if (this.$cookie.get("userInfo")) {
        this.userInfo = JSON.parse(this.$cookie.get("userInfo"));
      }
    },
  },
  created() {
    this.getUserInfo();
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@comColor: var(--theme);
@wrapPadding: calc(12rem / 16);
@comRadius: calc(10rem / 16);
@comBG: var(--bg1);

.wrap {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: var(--bg);

  .setting {
    padding: calc(10rem / 16) calc(15rem / 16);

    .content {
      position: relative;
      border-radius: calc(10rem / 16);
      background-color: rgba(19, 34, 53, .4);

      .state {
        background-color: #ade1f5;
      }

      .content-item {
        padding: calc(15rem / 16) calc(20rem / 16);
        color: var(--light);
        display: flex;
        justify-content: space-between;
        align-items: center;

        >div {
          display: flex;
          flex-direction: row;
          align-items: center;

          .item_text {
            font-size: calc(18rem / 16);
            color: var(--light);
          }

          .item_value {
            font-size: calc(14rem / 16);
            color: var(--colors);
            margin-right: calc(4rem / 16);
          }
        }
      }
    }
  }
}
</style>